<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid"/> Country Access Rules
        </slot>
      </CCardHeader>
      <CCardBody>
        <span style="width: 140px; display: inline-block">
          <CSelect
              style="margin-bottom: 0"
              :value.sync="selectedRule"
              :options="selectRuleOptions"
          />
        </span>
        <span @click.stop="showCountriesList = !showCountriesList"
              style="text-decoration: underline; text-decoration-style: dotted; margin-left: 10px; cursor:pointer;">these countries</span>
        <CButton style="margin-left: 10px;" color="primary" @click="saveCountries()">Save</CButton>
        <div class="container-fluid countries-list" v-if="showCountriesList">
          <div class="row">
            <CInputCheckbox
              class="col-3"
              v-for="item of sortedCountries"
              :key="item.code"
              :label="item.name"
              :name="`${item.name}`"
              :checked.sync="item.is_listed"
            />
          </div>
        </div>
      </CCardBody>

      <CElementCover v-if="isLoading">
        <CSpinner size="5xl" color="info"/>
      </CElementCover>

    </CCard>
  </div>
</template>

<script>
import CountryService from "@/services/CountryService";

export default {
  name: 'CountryAccessRules',
  components: {},
  data() {
    return {
      isLoading: false,
      countries: null,
      listedCountries: [],

      selectRuleOptions: [
        {
          value: 'allow',
          label: 'Allow only'
        },
        {
          value: 'deny',
          label: 'Deny'
        }
      ],
      selectedRule: '',
      showCountriesList: false
    }
  },
  methods: {
    async loadCountries() {
      try {
        this.isLoading = true;

        const response = await CountryService.getAll();

        const {rule, all, listed = []} = response.data;

        this.selectedRule = rule;
        this.normalizeCountries(all, listed)

      } finally {
        this.isLoading = false;
      }
    },
    async saveCountries() {
      try {
        this.isLoading = true;

        await CountryService.save({
          rule: this.selectedRule,
          codes: this.countries.filter(country => country.is_listed).map(country => country.code).join(',')
        });

      } finally {
        this.isLoading = false;
      }
    },
    normalizeCountries(countries, listed) {
      listed = listed.split(',');
      return this.countries = Object.entries(countries).map(item => ({
        code: item[0],
        name: item[1],
        is_listed: listed && listed.includes(item[0])
      }))
    },
  },
  computed: {
    sortedCountries() {
      return this.countries.sort((a,b) => {
        if (b.name > a.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },
  mounted() {
    this.loadCountries()
  }
}
</script>

<style lang="scss">
.countries-list {
  max-height: 400px;
  overflow-y: auto;
  border-top: 1px solid #ccc;
  margin-top: 15px;
  padding-top: 10px;
}
</style>
import {Auth} from "aws-amplify";
import {AbstractService} from "@/services/AbstractService";

export default new class DomainService extends AbstractService {
	async getAll() {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.get('/domain',
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}

	async delete(domain) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.delete(`/domain`,
			{
				headers: {
					Authorization: jwtToken
				},
				data: {
					domain
				},
			}
		)
	}
}

<template>
  <div>
    <CRow>
      <CCol lg="12">
        <AccessRules type="ip"/>
      </CCol>
      <CCol lg="12">
        <CountryAccessRules/>
      </CCol>
      <CCol lg="12">
        <AccessRules type="login"/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import dayjs from "dayjs";
import CountryAccessRules from "@/views/acl/CountryAccessRules";
import AccessRules from "@/views/acl/AccessRules";
import DomainService from "@/services/DomainService";

export default {
  name: 'AccessControl',
  components: {CountryAccessRules, AccessRules},
  data() {
    return {
      isLoading: false,
      columns: [
        {key: 'domain', label: 'Domain'},
        {key: 'key', label: 'Logs'},
        {key: 'created_at', label: 'Registered At'},
        {key: 'sub_status', label: 'Status'},
      ],
      domainsItems: []
    }
  },
  methods: {
    async loadDomains() {
      try {
        this.isLoading = true;

        const response = await DomainService.getAll();

        this.domainsItems = response.data;
        this.isLoading = false;

      } finally {
        this.isLoading = false;
      }
    },
    getColorByStatus(status) {
      switch(status) {
        case 'active': return 'success';
        case 'suspended': return 'secondary';
        case 'pending_deposit': return 'warning';
        case 'cancelled': return 'danger';
        default: return 'primary';
      }
    },
  },
  computed: {
    normalizedDomainItems() {
      return this.domainsItems.map(el => {

        return {
          domain: el.domain,
          key: el.network_id + el.domain + el.domain_key,
          created_at: dayjs.unix(el.created_at).format('MMM DD YYYY'),
          sub_status: el.sub_status || '-',
          sub_cancel_at: el.sub_cancel_at || '',
        }
      });
    }
  },
  mounted() {
    // this.loadDomains()
  }
}
</script>

<template>
  <div>
    <div v-if="isOptionsListOpened" @click="closeOptionsList" class="multi-select-outside-click"></div>
    <div class="multi-select-wrap">
      <CInput class="mb-0" placeholder="Domain" v-model="search" @focusin="openOptionsList"/>
      <div class="options-list" v-if="isOptionsListOpened">
        <label>
          <input type="checkbox" v-model="selectedAll" value="all" @change="onChangeSelectedOptions"> All
        </label>
        <label v-for="(option, key) in filteredOptions" :key="key">
          <input type="checkbox" v-model="selectedItems"
                 :value="option.value"
                 :disabled="selectedAll"
                 @change="onChangeSelectedOptions"> {{option.label || option.value}}
        </label>
        <div v-if="search !== null && !filteredOptions.length">No options</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiSelect',
  components: {},
  props: {
    options: Array
  },
  data() {
    return {
      selectedItems: [],
      search: null,

      isOptionsListOpened: false,
      selectedAll: false,
    }
  },
  methods: {
    openOptionsList() {
      this.isOptionsListOpened = true;
    },
    closeOptionsList() {
      this.isOptionsListOpened = false;
      this.search = '';
    },
    onChangeSelectedOptions(e) {
      const value = (this.selectedAll || this.selectedItems.length === 0) ? ['all'] : this.selectedItems;

      this.$emit('update:selected', value, e)
    },
  },
  computed: {
    filteredOptions() {

      if(!this.search) return this.options;

      return this.options.filter(item => item.value.indexOf(this.search) >= 0);
    }
  }
}
</script>
<style lang="scss" scoped>
.multi-select-wrap {
  position: relative;
  z-index: 999;

  .options-list {
    background-color: #fff;
    border-radius: 3px;
    padding: 5px;
    position: absolute;
    z-index: 999;
    border: 1px solid #d8dbe0;
    width: 100%;
    top: 40px;

    label {
      cursor: pointer;
      display: block;
      padding: 5px;
      border-radius: 3px;

      &:hover {
        background-color: #ebedef;
      }
    }
  }
}
.multi-select-outside-click {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
</style>
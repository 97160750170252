import {Auth} from "aws-amplify";
import {AbstractService} from "@/services/AbstractService";

export default new class AclService extends AbstractService {

	async getAll(type) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.get('/acl',
			{
				params: {
					type,
				},
				headers: {
					Authorization: jwtToken
				}
			},
			{type},
		)
	}

	async create(data) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.post('/acl/create',
			data,
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}

	async delete(data) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.post('/acl/delete',
			data,
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}
}

import {Auth} from "aws-amplify";
import {AbstractService} from "@/services/AbstractService";

export default new class CountryService extends AbstractService  {

	async getAll() {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.get('/country',
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}

	async save(data) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.post('/country',
			data,
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}
}

<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid"/> {{title}}
        </slot>
        <div class="card-header-actions">
          <a
              href="https://www.limitloginattempts.com/access-rules-explained/"
              class="card-header-action"
              rel="noreferrer noopener"
              target="_blank"
          >
            <small class="text-muted">Documentation</small>
          </a>
        </div>
      </CCardHeader>
      <CCardBody>
        <table class="table table-bordered mb-30 acl-table-head">
          <tbody>
            <tr>
              <td><CTextarea class="mb-0 acl-textarea-field"
                             :class="{focus: isPatternFieldFocused}"
                             v-model="pattern"
                             placeholder="Pattern" row="1" @click="togglePatternFieldFocus" @input="onInputTextarea"></CTextarea></td>
              <td style="width: 20%"><CSelect
                  class="mb-0"
                  :value.sync="selectedRule"
                  :options="selectRuleOptions"
              /></td>
              <td style="width: 20%">
                <MultiSelect :options="preparedDomains" @update:selected="onSelectDomains"></MultiSelect>
              </td>
              <td style="width: 20%"><CTextarea class="mb-0 acl-textarea-field"
                                                v-model="description"
                                                placeholder="Description" row="1"></CTextarea></td>
              <td align="center" style="width: 10%"><CButton color="primary" :disabled="!pattern" @click.prevent="createRule()">Add</CButton></td>
            </tr>
          </tbody>
        </table>
        <div class="acl-rules-table">
          <CDataTable
              border
              :items="normalizedItems"
              :fields="columns"
              :header="true"
              column-filter
              sorter
              :noItemsView="{
                noItems: 'No rules yet'
              }"
          >
            <template #action="{item}">
              <td style="width: 10%; text-align: center">
                <CButton color="danger" size="sm" @click.prevent="deleteRule(item.pattern, item.domain)">&times;</CButton>
              </td>
            </template>

            <template #rule="{item}">
              <td style="width: 20%;">
                {{item.rule}} <span class="rule-origin" v-if="item.origin">({{item.origin}})</span>
              </td>
            </template>

            <template #domain="{item}">
              <td style="width: 20%;">
                {{item.domain || ''}}
              </td>
            </template>

          </CDataTable>
        </div>
      </CCardBody>

      <CElementCover v-if="isLoading">
        <CSpinner size="5xl" color="info"/>
      </CElementCover>

    </CCard>
  </div>
</template>

<script>
import AclService from "@/services/AclService";
import {Auth} from "aws-amplify";
import axios from "axios";
import MultiSelect from "@/views/components/MultiSelect";
import DomainService from "@/services/DomainService";

export default {
  name: 'AccessRules',
  components: {
    MultiSelect
  },
  props: {
    type: String
  },
  data() {
    return {
      isLoading: false,

      columns: [
        {key: 'pattern', label: 'Pattern'},
        {key: 'rule', label: 'Rule', _classes: 'width20'},
        {key: 'domain', label: 'Domain', _classes: 'width20'},
        {key: 'description', label: 'Description', _classes: 'width20'},
        {key: 'action', label: 'Action', filter: false, _classes: 'width10'},
      ],
      items: [],
      domainsItems: [],
      selectedDomains: [],

      pattern: '',
      description: '',
      selectRuleOptions: [
        {
          value: 'deny',
          label: 'Deny'
        },
        {
          value: 'allow',
          label: 'Allow'
        },
        {
          value: 'pass',
          label: 'Pass'
        }
      ],
      selectedRule: 'deny',

      isPatternFieldFocused: false
    }
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;

        const response = await AclService.getAll(this.type);

        const {items} = response.data;

        this.items = [
          ...this.items,
          ...items
        ];

      } finally {
        this.isLoading = false;
      }
    },
    async loadDomains() {
      try {
        this.isLoading = true;
        const response = await DomainService.getAll();

        const {domains} = response.data;

        this.domainsItems = domains;
        this.isLoading = false;

      } finally {
        this.isLoading = false;
      }
    },
    async createRule() {

      if(!this.pattern) {
        alert('The pattern is required');
        return;
      }

      if(this.selectedDomains.length === 0) {
        alert('The domain is required.');
        return;
      }

      try {
        this.isLoading = true;

        const response = await AclService.create({
          pattern: this.pattern,
          description: this.description,
          type: this.type,
          rule: this.selectedRule,
          domains: this.selectedDomains[0] === 'all' ? [''] : this.selectedDomains
        });

        console.log(response);

        const patterns = this.pattern.split('\n');

        const newItems = [];
        for(const domain of this.selectedDomains) {
          for(const pattern of patterns) {

            const newItem = {
              pattern,
              description: this.description,
              domain: domain === 'all' ? '' : domain,
              rule: this.selectedRule,
            };

            if(this.type === 'ip') {
              newItem['origin'] = 'manual';
            }

            newItems.push(newItem);
          }
        }

        const newPatterns = newItems.map(item => item.pattern);

        this.items = [
          ...this.items.filter(item => !(newPatterns.includes(item.pattern) && this.selectedDomains.includes(item.domain))),
          ...newItems
        ];

        this.resetFormFields();

      } finally {
        this.isLoading = false;
      }
    },
    async deleteRule(pattern, domain) {

      if(!confirm("Are you sure?")) return;

      try {
        this.isLoading = true;

        const response = await AclService.delete({
          pattern,
          domain,
          type: this.type
        });

        console.log(response.data);

        this.items = this.items.filter(item => !(item.pattern === pattern && item.domain === domain));

      } finally {
        this.isLoading = false;
      }
    },
    resetFormFields() {
      this.pattern = '';
      this.description = '';
      this.selectedRule = 'deny';
    },
    onSelectDomains(value) {
      this.selectedDomains = value;
    },
    togglePatternFieldFocus() {
      this.isPatternFieldFocused = true;
    },
    onInputTextarea(val, event) {
      const $target = event.target;
      $target.style.height = 'auto';
      $target.style.height = ($target.scrollHeight) + "px";
    }
  },
  computed: {
    preparedDomains() {
      return this.domainsItems.map(item => ({
        label: item.domain,
        value: item.domain,
      }))
    },
    normalizedItems() {
      return this.items.map(item => ({
        pattern: item.pattern,
        rule: item.rule,
        domain: item.domain || '',
        description: item.description || '',
        origin: item.origin || '',
        _classes: [`row-bg-color-${item.rule}`]
      }))
    },
    allowedDomains() {
      return [...new Set(this.normalizedItems.map(item => item.domain))]
    },
    title() {
      return (this.type === 'login' ? 'Login' : 'IP') + ' Access Rules';
    },
    disableInfinity() {
      return !this.loadDataOffset;
    }
  },
  mounted() {
    const inputs = document.querySelectorAll('.acl-rules-table th input');
    inputs.forEach((el) => el.setAttribute('placeholder', 'search'));

    this.loadData()
    this.loadDomains()
  }
}
</script>

<style lang="scss">
.width20 {
  width: 20%;
}
.width10 {
  width: 10%;
}
.acl-table-head {
  background-color: #f7f7f7;

  button {
    &:disabled {
      cursor: default;
    }
  }
}
.row-bg-color-allow {
  td {
    background-clip: padding-box;
    background-color: #abdfff;
    border-color: #b9b9b9;
  }
}
.row-bg-color-deny {
  td {
    background-clip: padding-box;
    background-color: #ffcccc;
    border-color: #b9b9b9;
  }
}
.row-bg-color-pass {
  td {
    background-clip: padding-box;
    background-color: #cffbe8;
    border-color: #b9b9b9;
  }
}
.rule-origin {
  font-size: 12px;
  color: #999;
}
.scrollable-table-wrapper {
  overflow-y: auto;
  max-height: 400px;
}
.acl-rules-table {
  td[colspan="5"] {
    display: none;
  }
  table {
    th {
      vertical-align: top;

      input::placeholder {
        font-style: italic;
      }
    }
  }
}
.acl-textarea-field {
  textarea {
    resize: none;
    height: calc(1.5em + 0.75rem + 2px);
  }
  &.focus {
    textarea {
      height: auto;
    }
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.title)+" ")]}),_c('div',{staticClass:"card-header-actions"},[_c('a',{staticClass:"card-header-action",attrs:{"href":"https://www.limitloginattempts.com/access-rules-explained/","rel":"noreferrer noopener","target":"_blank"}},[_c('small',{staticClass:"text-muted"},[_vm._v("Documentation")])])])],2),_c('CCardBody',[_c('table',{staticClass:"table table-bordered mb-30 acl-table-head"},[_c('tbody',[_c('tr',[_c('td',[_c('CTextarea',{staticClass:"mb-0 acl-textarea-field",class:{focus: _vm.isPatternFieldFocused},attrs:{"placeholder":"Pattern","row":"1"},on:{"click":_vm.togglePatternFieldFocus,"input":_vm.onInputTextarea},model:{value:(_vm.pattern),callback:function ($$v) {_vm.pattern=$$v},expression:"pattern"}})],1),_c('td',{staticStyle:{"width":"20%"}},[_c('CSelect',{staticClass:"mb-0",attrs:{"value":_vm.selectedRule,"options":_vm.selectRuleOptions},on:{"update:value":function($event){_vm.selectedRule=$event}}})],1),_c('td',{staticStyle:{"width":"20%"}},[_c('MultiSelect',{attrs:{"options":_vm.preparedDomains},on:{"update:selected":_vm.onSelectDomains}})],1),_c('td',{staticStyle:{"width":"20%"}},[_c('CTextarea',{staticClass:"mb-0 acl-textarea-field",attrs:{"placeholder":"Description","row":"1"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('td',{staticStyle:{"width":"10%"},attrs:{"align":"center"}},[_c('CButton',{attrs:{"color":"primary","disabled":!_vm.pattern},on:{"click":function($event){$event.preventDefault();return _vm.createRule()}}},[_vm._v("Add")])],1)])])]),_c('div',{staticClass:"acl-rules-table"},[_c('CDataTable',{attrs:{"border":"","items":_vm.normalizedItems,"fields":_vm.columns,"header":true,"column-filter":"","sorter":"","noItemsView":{
              noItems: 'No rules yet'
            }},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticStyle:{"width":"10%","text-align":"center"}},[_c('CButton',{attrs:{"color":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.deleteRule(item.pattern, item.domain)}}},[_vm._v("×")])],1)]}},{key:"rule",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(item.rule)+" "),(item.origin)?_c('span',{staticClass:"rule-origin"},[_vm._v("("+_vm._s(item.origin)+")")]):_vm._e()])]}},{key:"domain",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(item.domain || '')+" ")])]}}])})],1)]),(_vm.isLoading)?_c('CElementCover',[_c('CSpinner',{attrs:{"size":"5xl","color":"info"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }